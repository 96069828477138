.container {
  margin: var(--unit_10) 0 var(--unit_8);
}

.list {
  margin: var(--unit_3) 0 0;
  padding: 0;
  list-style-type: none;
}

.item + .item {
  border-top: 1px solid var(--gray10_100);
}

.item-link {
  padding: var(--unit_3) 0;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.item-title {
  flex-grow: 1;
  margin-right: var(--unit_3);
}

.item-count {
  margin-right: var(--unit_3);
}

.item-icon {
  flex-shrink: 0;
}
