@import '@cian/ui-kit/colors/variables.css';

.container {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container + .container {
  margin-top: 28px;
}
