/* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */

.collapsed {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  /* заменить на line-clamp, когда будет поддержка */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.button {
  margin-top: var(--unit_4);
}
