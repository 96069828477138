.postfix {
  color: var(--black_100);
}

/** Перекрываем стиль в китовом инпуте, из-за которого при дебаунсе при вводе значения оно стилизуется серым */
/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global {
  input[value=''] {
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--superblack_100) !important;
  }
}
