@import '@cian/ui-kit/colors/variables.css';

.button {
  padding: 0 16px;
  display: grid;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: var(--black_100);
  background: var(--white_100);
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
  align-items: center;
  grid-template-columns: auto 1fr;
}

.button:hover {
  background-color: var(--black_10);
}

.button:active {
  background-color: var(--black_20);
}

.button:focus {
  box-shadow: 0 0 1px 1px var(--focused_15);
}

@media (min-width: 641px) {
  .button {
    padding: 0 12px;
  }
}

.icon_wrapper {
  width: 16px;
  height: 15px;
}

.button_text {
  margin-left: 8px;
  display: block;
  overflow: hidden;
  font-size: 16px;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--black_100);
}

.button_text_empty {
  color: var(--gray40_100);
}
