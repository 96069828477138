.container {
  margin: var(--unit_8) 0;
  padding-bottom: var(--unit_2);
}

.text {
  margin-top: var(--unit_5);
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
  color: var(--black_100);
}

.text > * {
  margin: 0;
  padding: 0;
}
