.container {
  position: relative;
  max-width: 392px;
  height: 40px;
}

.container-phone {
  max-width: 459px;
}

.text-value {
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 50%;
  display: flex;
  height: 38px;
  background: white;
  transform: translateX(-50%);
  align-items: center;
}
