.button {
  padding: 0;
  display: inline-flex;
  align-items: center;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  cursor: pointer;
  color: var(--primary_100);
  background: transparent;
  border: none;
}

.icon {
  margin-left: var(--unit_1);
  height: var(--unit_4);
}
