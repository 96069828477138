.container {
  padding: var(--unit_2) 0 var(--unit_6);
  background-color: #e6f0ff;
}

.heading {
  margin-bottom: var(--unit_2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  margin-top: var(--unit_4);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--unit_2);
}

.all-filters {
  display: flex;
  height: 40px;
  text-decoration: none;
  background: transparent;
  border: none;
  outline: none;
  align-items: center;
}

.filter-text {
  margin-left: var(--unit_2);
}
