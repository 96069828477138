.container {
  --link-gap: 6px;

  margin: var(--unit_8) 0;
  padding-bottom: var(--unit_2);
}

.links {
  margin: var(--unit_1) calc(-1 * var(--link-gap)) 0;
}

.link {
  margin: var(--unit_2) var(--link-gap) 0;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px dashed var(--primary_100);
}
