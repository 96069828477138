@import '@cian/ui-kit/colors/variables.css';

.button {
  padding: 8px 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  height: 40px;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  text-align: left;
  cursor: pointer;
  color: var(--black_100);
  background: var(--white_100);
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}

.button:hover {
  background-color: var(--black_10);
}

.button:active {
  background-color: var(--black_20);
}

.button:focus {
  box-shadow: 0 0 1px 1px var(--focused_15);
}

.label {
  padding-right: var(--unit_2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow {
  transform: rotate(90deg);
}

@media (min-width: 641px) {
  .button {
    padding: 3px 12px;
    font-size: var(--fontSize_14px);
    line-height: var(--lineHeight_20px);
  }
}
