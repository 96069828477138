.container {
  margin: calc(-1 * var(--unit_1));
  padding: var(--unit_1);
}

.collapsed {
  overflow: hidden;
}

.button {
  margin-top: var(--unit_4);
}
