@import './ReactDateRange.css';

.reset_button {
  margin-right: -12px;
  padding: 2px 12px;
  cursor: pointer;
  background: none;
  border: 0;
}

.content {
  overflow-y: scroll;
  height: 100%;
}

.action_buttons {
  position: sticky;
  bottom: 0;
  padding: 12px 16px;
  width: 100%;
  background-color: white;
  border-top: 1px solid var(--gray10_100);
}
