.header-button {
  display: flex;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: space-between;
}

.header {
  margin-right: var(--unit_2);
}
