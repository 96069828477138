/* stylelint-disable */
:global {
  .rdrCalendarWrapper {
    background: var(--white_100);
    display: flex;
    flex-direction: column;
    user-select: none;
    color: var(--black_100);
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }

  .rdrMonthAndYearWrapper {
    display: none !important;
  }

  .rdrWeekDays {
    display: flex;
    position: fixed;
    width: 100%;
    padding: 4px 16px;
    z-index: 1;
    background: #fff;
  }

  .rdrWeekDay {
    flex-basis: calc(100% / 7);
    text-align: center;
    padding: 8px 0;
    text-transform: capitalize;
  }

  .rdrInfiniteMonths {
    margin-top: 48px;
    padding: 0 16px;
  }

  .rdrMonth {
    width: 100% !important;
  }

  .rdrMonthName {
    text-align: left;
    font-weight: bold;
    color: var(--black_100);
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
  }

  .rdrDays {
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0 0;
  }

  .rdrDay {
    width: calc(100% / 7);
    position: relative;
    cursor: pointer;
    background: transparent;
    user-select: none;
    border: 0;
    margin: 6px 0;
    text-align: center;
  }

  .rdrDay:focus {
    outline: 0;
  }

  .rdrDayPassive {
    pointer-events: none;
    visibility: hidden;
  }

  .rdrDayDisabled {
    .rdrDayNumber span {
      color: var(--gray40_100);
    }
  }

  .rdrDayDisabled {
    cursor: not-allowed;
  }

  .rdrDayNumber {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    position: relative;
  }

  .rdrDayNumber span {
    color: var(--black_100);
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background: var(--primary_100);
  }

  .rdrDay:not(.rdrDayPassive) .rdrStartEdge,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge {
    & ~ .rdrDayNumber span {
      color: var(--white_100);
    }
  }

  .rdrStartEdge {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .rdrEndEdge {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .rdrInRange {
    background: var(--primary_10);
  }
}

/* stylelint-enable */
