/*
  Костыль, который переписывает стили стандартных радио-кнопок,
  который делает их во всю ширину контейнера и делает подложку белого цвета
*/
.container {
  & label {
    background-color: var(--white_100);

    &:not(:first-child) > span {
      width: 100%;
      justify-content: center;
    }
  }

  & > div {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    width: 100%;
  }
}
