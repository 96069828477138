.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.options {
  overflow-y: scroll;
  flex: 1;
}

.header {
  margin: 16px 0;
  padding: 0 16px;
}
